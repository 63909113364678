<template>
  <div class="container-special fill-height">
    <div class="row fill-height align-items-center justify-content-between">

      <div class="col-5 col-lg-3 col-md-3 col-lg-2 d-flex justify-content-start align-items-center">
        <div class="d-flex d-md-none">
          <p @click="goToContact" class="ml-sm-1 mt-3"><strong>Kontakt</strong></p>
        </div>
        <div class="d-none d-md-flex">
          <strong><a class="contact" href="mailto:biuro@dofinansowaniaslask.pl">biuro@dofinansowaniaslask.pl</a></strong>
        </div>
      </div>
      <div class="col">
        <p class="ready-sets-router" ><router-link to="/gotowe-zestawy">gotowe zestawy</router-link> </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'headerComponent',
  computed: {

  },
  methods: {
    // goToHome(){
    //   this.$router.push('/')
    // },
    goToContact(){
    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
    }
  }
}
</script>

<style scoped lang="scss">
.fill-height {
  height: 100%;
}

img[alt="logo"] {
  max-height: 40px;
}

.ready-sets-router *{
  color: #ffff;
  font-weight: bold;
  font-size: 18px;
  text-decoration: none;
}
.ready-sets-router{
  text-align: end;
  margin: 0;
}
.ready-sets-router:hover{
  cursor: pointer;
}
 a:hover {
  color: inherit;
  text-decoration: underline;
}

@media only screen and (min-width: 2000px) {
  .container-special  {
    max-width: 1730px;
    margin: auto;
    width: 100%;
    padding: 5px 0px;
  }
}

@media only screen and (max-width: 900px) {
  .container-special {
    padding: 10px 30px;
  }

  img[alt="logo"] {
    max-height: 30px;
  }

}

@media only screen and (max-width: 510px) {
  .container-special {
    padding: 0px 0px;
    margin: 0px 10px;
  }
  .ready-sets-router *{
  color: #ffff;
  font-weight: bold;
  font-size: 15px;
  text-decoration: none;
}

  img[alt="logo"] {
    max-height: 30px;
  }

}

@media only screen and (max-width: 450px) {
  img[alt="logo"] {
    max-height: 25px;
  }
}
</style>
