<template>
  <div class="row align-items-center justify-content-center">
    <div class="col-12">
      <div class="row images">
        <div class="col-3 p-0">
          <img
            width="100%"
            height="100%"
            alt="slask"
            class=""
            src="../assets/slask.jpg"
          />
        </div>
        <div class="col-3 p-0">
          <img
            width="100%"
            height="100%"
            alt="home-pv"
            class=""
            src="../assets/pv-home.jpg"
          />
        </div>
        <div class="col-3 p-0">
          <img
            width="100%"
            height="100%"
            alt="spodek"
            class=""
            src="../assets/spodek.jpg"
          />
        </div>
        <div class="col-3 p-0">
          <img
            width="100%"
            height="100%"
            alt="pv-modules"
            class=""
            src="../assets/pv-modules.jpg"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-special">
    <div class="row justify-content-start my-2 mt-md-5">
      <div class="col-12 col-md-10">
        <p class="text-left first-title">
          Projekty wsparcia indywidualnych inwestycji w odnawialne źródła energii
          <span class="green-text"> w województwie śląskim</span>
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 details-info">
        <p>
          <strong>Województwo śląskie</strong>, stanowiące przez dekady jeden z
          najważniejszych ośrodków przemysłowych w kraju, dzięki inicjatywie Europejskiego
          Zielonego Ładu, otrzyma olbrzymie wsparcie finansowe w ramach
          <strong>Funduszu Sprawiedliwej Transformacji.</strong>
        </p>
        <p>
          Dzięki kwocie <strong>2,2 mld euro</strong> możliwe będzie łagodzenie skutków
          transformacji przemysłowej, związanej z przekształcaniem sektora węglowego.
          Wsparcie udzielane będzie osobom prywatnym i firmom.
        </p>
        <p>
          <strong>Fundusz Sprawiedliwej Transformacji</strong> to olbrzymia szansa na
          mieszkańców województwa. Dzięki dodatkowym środkom finansowym możliwe stanie się
          pozyskanie znacznej sumy pieniędzy na
          <strong
            >sfinansowanie przydomowych instalacji fotowoltaicznych, pomp ciepła</strong
          >
          czy magazynów energii.
        </p>
      </div>
    </div>

    <div class="row mt-5">
      <div
        class="col-12 col-lg-7 col-xl-6 d-flex justify-content-center align-items-center"
      >
        <div ref="svg"></div>
      </div>
      <div
        class="col-12 col-lg-5 col-xl-6 d-flex flex-column justify-content-center mt-5 mt-lg-0"
      >
        <p class="clickable_city" @click="setInfo('Bytom')">
          <i class="bi bi-caret-right-fill mr-2"></i>Bytom
        </p>
        <p class="clickable_city" @click="setInfo('Bielsko-Biała')">
          <i class="bi bi-caret-right-fill mr-2"></i>Bielsko-Biała
        </p>
        <p class="clickable_city" @click="setInfo('Czechowice-Dziedzice')">
          <i class="bi bi-caret-right-fill mr-2"></i>Czechowice-Dziedzice
        </p>
        <p class="clickable_city" @click="setInfo('Rybnik')">
          <i class="bi bi-caret-right-fill mr-2"></i>Rybnik
        </p>
        <p class="clickable_city" @click="setInfo('Tarnowskie Góry')">
          <i class="bi bi-caret-right-fill mr-2"></i>Tarnowskie Góry
        </p>
        <p class="clickable_city" @click="setInfo('Tychy')">
          <i class="bi bi-caret-right-fill mr-2"></i>Tychy
        </p>
      </div>
      <div
        class="col-12 mt-5 p-3"
        :class="actualDescription ? 'border-dashed mb-5' : ''"
        id="actualDescription"
      >
        <p v-html="actualDescription"></p>
      </div>
    </div>
    
    <!-- <div class="row p-2 p-md-0">

      <p style="font-size: 30px; font-weight: bold" class="px-3 px-sm-0">
        Skontaktuj się z nami
      </p>
      <div class="col-12 border-top-dashed"></div>

      <div class="row w-100 instalators">
        <div class="col-12 mt-3 mb-4">
          <p class="mb-0"><strong>Umów spotkanie lub zapytaj o szczegóły:</strong></p>
          <a class="" href="mailto:biuro@dofinansowaniaslask.pl">biuro@dofinansowaniaslask.pl</a><br/>
          <p class="mt-3 mb-0"><strong>Zapytaj eksperta:</strong></p>
          <a href="tel:+48501924953">tel: 501 924 953</a>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "allContentComponent",
  components: {},
  data() {
    return {
      title: "",
      id: "",
      descriptionOfCity: [
        {
          id: "Bytom",
          description: `
          <h3>Bytom</h3>
          <p class="mt-3">55,3 mln zł unijnego dofinansowania trafi wkrótce do mieszkańców Bytomia i Radzionkowa. Jest to możliwe dzięki projektowi przewidującego zakup i montaż instalacji wykorzystujących Odnawialne Źródła Energii.</p> 
          <p>Mieszkańcy zyskają możliwość instalacji paneli fotowoltaicznych, powietrznych lub gruntowych pomp ciepła do c.o. wraz z c.w.u, magazynów energii elektrycznej oraz systemów zarządzania energią.</p>
          <p>Z dofinansowania mogą skorzystać właściciele lub współwłaściciele domów jednorodzinnych, położonych na terenie Bytomia, którzy dopiero planują inwestycję. W projekcie tym nie mają znaczenia dochody grantobiorców. Mieszkaniec płaci za inwestycję z własnych środków, kolejno otrzymuje częściową refundację poniesionych kosztów.</p>
          <div class="row p-static">
          <div class="col-12 col-xl-7">
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1 mt-5">
              <p class="grant-info">grant do<br/>
                50 000 zł</p>
              <p class="grant-info-desc">fotowoltaika</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                70 000 zł</p>
              <p class="grant-info-desc">powietrzna pompa ciepła do c.o. oraz c.w.u.</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                70 000 zł</p>
              <p class="grant-info-desc">gruntowa pompa ciepła do c.o. oraz c.w.u</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                35 000 zł</p>
              <p class="grant-info-desc">magazyn energii elektrycznej</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                2 000 zł</p>
              <p class="grant-info-desc">system zarządzania energią</p>
              </div>
              
           </div>
             
          </div>
          `,
        },
        {
          id: "Bielsko-Biała",
          description: `
          <h3>Bielsko-Biała</h3>
          <p class="mt-3">“Odnawialne źródła energii dla mieszkańców Bielska-Białej i Zebrzydowic” to jeden z siedmiu zwycięskich projektów, wybranych do dofinansowania w ramach programu Fundusze Europejskie dla Śląskiego 2021-2027. Całkowita wartość projektu to aż 43,4 mln złotych.</p><p>Bielszczanie mogą skorzystać z grantów na 1200 instalacji OZE, w tym: 480 instalacji fotowoltaicznych, 90 instalacji kolektorów słonecznych oraz 630 pomp ciepła do centralnego ogrzewania lub centralnego ogrzewania i ciepłej wody użytkowej. Dodatkowo granty obejmą 360 magazynów energii cieplnej i 600 magazynów energii elektrycznej.</p><p>W projekcie przewidziane jest udzielanie grantów w wysokości do 85% kosztów kwalifikowanych (netto). Podatek VAT nie będzie kosztem kwalifikowanym.</p>
          <p><strong>W jaki sposób wypłacane będzie dofinansowanie?</strong></p>
          <p>Dofinansowanie będzie wypłacane wyłącznie w formie refundacji poniesionych kosztów, co oznacza, że grantobiorca musi zabezpieczyć 100 proc. środków na zapłatę wykonawcy.<br/>Warto pamiętać, że drugi nabór na inwestycje zaplanowane do realizacji w latach 2025-2026 będzie prowadzony w roku 2025. Informacja o terminie udostępnione zostaną w oficjalnym ogłoszeniu. Lista podstawowa/rezerwowa pierwszego naboru nie obowiązuje do drugiego naboru.</p>
          <div class="row p-static">
         <div class="col-12 col-xl-7">
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1 mt-5">
              <p class="grant-info">grant do<br/>
                11 900 zł</p>
              <p class="grant-info-desc">fotowoltaika</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                25 500 zł</p>
              <p class="grant-info-desc">pompa ciepła (ciepła woda i/lub ogrzewanie)</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                5 100 zł</p>
              <p class="grant-info-desc">magazyn energii cieplnej</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                20 400 zł</p>
              <p class="grant-info-desc">magazyn energii elektrycznej</p>
          </div>
          <div class="row d-flex flex-column flex-sm-row text-center text-sm-left align-items-center ml-1">
              <p class="grant-info">grant do<br/>
                8 500 zł</p>
              <p class="grant-info-desc">kolektory słoneczne</p>
          </div>
          </div>
          <div class="col-12 col-xl-5 mt-5 meet-terms">
             <div class="mr-0 mr-sm-5">
                <p>Od 1 lipca 2024 r. od godziny 8.00 do 2 sierpnia 2024 r. do godziny 12.00 będzie prowadzony pierwszy nabór bielszczan zainteresowanych udziałem w projekcie.</p>
                <p>Deklaracje udziału w projekcie będą przyjmowane w budynku Bielskiego Centrum Kultury im. Marii Koterbskiej w Bielsku-Białej przy ul. Juliusza Słowackiego 27 (wejście do sali koncertowej od strony parku):</p>
                <ul>
                  <li>poniedziałki, wtorki i środy w godzinach 8.00-15.00,</li>
                  <li>w czwartki 8.00-17.00,</li>
                  <li>piątki 8.00-12.00. </li>
                  </ul>
                  <p>Analogiczny nabór dla mieszkańców gminy Zebrzydowice będzie ogłoszony w Urzędzie Gminy Zebrzydowice.</p>
              </div>
               </div>
          </div>
          `,
        },
        {
          id: "Czechowice-Dziedzice",
          description: `
          <h3>Czechowice-Dziedzice</h3>
          <p class="mt-3">„Przejście od węgla do energii słonecznej - promowanie odnawialnych źródeł energii dla mieszkańców gmin Czechowice-Dziedzice i Hażlach” to zwycięski projekt, dzięki któremu do rozdysponowania jest 23,8 mln zł.</p> 
          <p>Grant ten przeznaczony jest na dostawę i montaż 721 szt. instalacji OZE w budynkach mieszkalnych będących własnością mieszkańców Gminy Czechowice-Dziedzice i Gminy Hażlach, w tym:</p>
          <ul>
            <li>261 instalacji fotowoltaicznych,</li>
            <li>30 instalacji solarnych,</li>
            <li>15 pomp ciepła do przygotowania ciepłej wody użytkowej,</li>
            <li>403 pomp ciepła dla potrzeb centralnego ogrzewania i przygotowania ciepłej wody użytkowej,</li>
            <li>12 kotłów na biomasę,</li>
            <li>361 magazynów energii elektrycznej z systemem zarządzania energią elektryczną.</li>
            </ul>
            <p>Dofinansowanie będzie wynosić aż 85% wartości netto inwestycji.
            </p>
            <p><strong>W jaki sposób uzyskać dofinansowanie?</strong></p>
            <p>Osoby zainteresowane powinny udać się do właściwego dla swojego miejsca zamieszkania urzędu gminy - tam możliwe będzie uzyskanie szczegółowych informacji dotyczących naboru i wniosków. Urzędnicy podpowiedzą, jakie dokumenty będą niezbędne do złożenia prawidłowego wniosku.</p>
            <p>Warto pamiętać, by do wykonania instalacji OZE wybrać doświadczoną firmę. Firma taka przede wszystkim wykona niezbędne audyty oraz pomiary, kolejno zaproponuje urządzenia dopasowane do potrzeb i kosztorysu. Montaż i uruchomienie instalacji odbywa się we współpracy z inspektorem, którego wyznaczy urząd miasta lub gminy. Po zakończeniu montażu i oddaniu instalacji do użytku inwestor powinien otrzymać komplet dokumentów potwierdzających wykonanie inwestycji - jest to niezbędne do uzyskania dofinansowania!</p>
          `,
        },
        {
          id: "Rybnik",
          description: `
          <h3>Rybnik</h3>
          <p class="mt-3">
            W ramach projektu dla miasta Rybnik przewidziano łącznie instalację 2596 sztuk instalacji OZE oraz 1678 sztuk magazynów energii elektrycznej i cieplnej.</p>
            <p>Projekt rybnicki, który obejmuje także akcję edukacyjną będzie realizowany w okresie 2024-2026. Olbrzymie dofinansowanie pozwoli na realizację grantu zarówno dla Rybnika, ale też miasta Czerwionka-Leszczyny, Gaszowice, Jejkowice, Lyski, Pszów i Świerklany.
            </p>
            <p>Projekt zakłada, że właściciel nieruchomości będzie mógł uzyskać nawet do 104 tys. zł dofinansowania w przypadku zakupu zestawu obejmującego pompę ciepła do c.o. oraz c.w.u., instalację fotowoltaiczną oraz magazyn energii elektrycznej wraz z system zarządzania energią.</p>
            <p><strong>Co należy zrobić, aby uzyskać dofinansowanie?</strong></p>
            <p>Aby skorzystać z dofinansowania do instalacji OZE w 2024 roku, należy złożyć wniosek do odpowiedniej instytucji - można zrobić to osobiście lub online. Dokładnych informacji na temat wymogów formalnych, terminów naboru wniosków i warunków projektu udzieli dział zajmujący się wnioskami. Znajdziemy go w odpowiednim do miejsca zamieszkania urzędzie gminy. Urzędy mają obowiązek na bieżąco informować o kolejnych etapach przeprowadzania dofinansowania.</p>
          `,
        },
        {
          id: "Tarnowskie Góry",
          description: `
          <h3>Tarnowskie Góry</h3>
          <p class="mt-3">Rozwój energii rozproszonej opartej na odnawialnych źródłach energii w Tarnowskich Górach, Bobrownikach i Świerklanach” to projekt, który otrzymał wsparcie 109 mln złotych.</p>
          <p>W ramach projektu zamontowanych zostanie w trzech gminach 827 pomp ciepła, 831 instalacji PV oraz 836 magazynów energii, do tego systemy zarządzania energią. <br/> Planowane są również kampanie edukacyjne, kierowane do mieszkańców, których celem będzie zwrócenie uwagi na możliwości OZE oraz poprawę jakości środowiska dzięki ich wykorzystaniu.</p>
          <p>Projekt grantów nie przewiduje żadnych szczególnych wymagań co do wyboru dostawców i wykonawców instalacji OZE, co pozwala na skrócenie czasu finalizacji inwestycji do minimum. Inwestor sam wybiera wykonawcę, któremu powierzy wykonanie prac instalacyjnych. Istotnym czynnikiem podczas wyboru wykonawcy jest doświadczenie. Wiedza i umiejętności instalatorów przełożą się na wybór dopasowanych do potrzeb urządzeń.</p>
          `,
        },
        {
          id: "Tychy",
          description: `
          <h3>Tychy</h3>
          <p class="mt-3">Program: "Postaw na OZE - rozwój energetyki rozproszonej opartej o odnawialne źródła energii na terenie Gmin Partnerskich Tychy i Wyry" zdobył dotację 93 mln złotych. Gmina przeznaczy je na dofinansowanie urządzeń pozwalających na pozyskiwanie odnawialnych źródeł energii (instalacji fotowoltaicznych, pomp ciepła, magazynów energii).</p>
          <p>Nabór wniosków od drugiej połowy 2024 roku.</p>
          <p>Zgodnie z założeniami konkursu, projekt skierowany jest do osób indywidualnych, będących właścicielem lub współwłaścicielem budynku mieszkalnego (w tym domu jednorodzinnego) położonego na terenie gmin Tychy i Wyry.<br/>Warto pamiętać, aby planując inwestycję w OZE, wybrać doświadczonych instalatorów i wykonawców. Wyłącznie wieloletnie doświadczenie jest gwarancją prawidłowo wykonanej instalacji oraz skuteczności jej działania.</p>
          `,
        },
      ],
      actualDescription: "",
      instalators: [
        {
          id: 1,
          city: "Chorzów",
          company: "Grund Doradztwo Energetyczne",
          name: "Adam Grund",
          phone: "+48 791 277 846",
          email: "adam.grund@ekobudownictwo.com",
        },
        {
          id: 2,
          city: "Gliwice",
          company: "Elektryka i Automatyka",
          name: "Dawid Tomczyszyn",
          phone: "+48 500 120 281",
          email: "dawid.tomczyszyn@gmail.com",
        },
        {
          id: 3,
          city: "Jaworzno",
          company: "INSTAL-TECH",
          name: "Maciej Sroka",
          phone: "+48 535 004 437",
          email: "biuro@instal-tech.info",
        },
        {
          id: 4,
          city: "Katowice",
          company: "ManuFaktura Energii",
          name: "Grzegorz Sobota",
          phone: "+48 601 699 815",
          email: "grzegorz.manufakturaenergii@gmail.com",
        },
        {
          id: 5,
          city: "Siemianowice Śląskie",
          company: "Brilliant Finish Sp z o.o.",
          name: "Grzegorz Kania",
          phone: "+48 538 762 511",
          email: "biuro@brilliantfinish.pl",
        },
        {
          id: 6,
          city: "Siemianowice Śląskie",
          company: "Yell sp. z o.o.",
          name: "Dariusz Krzykawski",
          phone: "+48 575 900 110",
          email: "krzykawski@yell.com.pl",
        },
        {
          id: 7,
          city: "Tarnowskie Góry",
          company: "Altech Power Sp. z o.o.",
          name: "Mirosław Bucholc",
          phone: "+48 602 769 155",
          email: "m.bucholc@e-altech.pl",
        },
        {
          id: 8,
          city: "Tarnowskie Góry",
          company: "CENTRAL SERWIS - Systemy Grzewcze",
          name: "Jarosław Stasiów",
          phone: "+48 663 383 991",
          email: "serwis@centralserwis.com.pl",
        },
        {
          id: 9,
          city: "Chorzów",
          company: "Grund Doradztwo Energetyczne",
          name: "Adam Grund",
          phone: "+48 791 277 846",
          email: "adam.grund@ekobudownictwo.com",
        },
        {
          id: 10,
          city: "Gliwice",
          company: "Elektryka i Automatyka Dawid Tomczyszyn",
          name: "Dawid Tomczyszyn",
          phone: "+48 500 120 281",
          email: "dawid.tomczyszyn@gmail.com",
        },
        {
          id: 11,
          city: "Jaworzno",
          company: "INSTAL-TECH Maciej Sroka",
          name: "Maciej Sroka",
          phone: "+48 535 004 437",
          email: "biuro@instal-tech.info",
        },
        {
          id: 12,
          city: "Bytom",
          company: "CEN-RAT Sp. z o.o.",
          name: "Jacek Reimann",
          phone: "+48 696 045 192",
          email: "j.reimann@cen-rat.bytom.pl",
        },
        {
          id: 13,
          city: "Gliwice",
          company: "InstaPV JAROSŁAW CHACIŃSKI",
          name: "Jarosław Chaciński",
          phone: "+48 502 427 847",
          email: "j.chacinski@instapv.pl",
        },
        {
          id: 14,
          city: "Katowice",
          company: "Profikams Sebastian Zembalowski",
          name: "Sebastian Zembalowski",
          phone: "+48 601 328 808",
          email: "biuro@profikams.pl",
        },
        {
          id: 15,
          city: "Katowice",
          company: "AS ECO Sp. z o.o.",
          name: "Adam Socha",
          phone: "+48 793 292 200",
          email: "as.eco.technic@gmail.com",
        },
        {
          id: 16,
          city: "Mysłowice",
          company: "Solter System",
          name: "Marcin Pałasz",
          phone: "+48 792 422 604",
          email: "m.palasz@soltersystem.pl",
        },
        {
          id: 17,
          city: "Ruda Śląska",
          company: "PERTIS KAROLINA PERCHEL",
          name: "Damian Perchel",
          phone: "+48 570 494 494",
          email: "d.perchel@pertis.pl",
        },
        {
          id: 18,
          city: "Katowice",
          company: "Fotovoltsystem",
          name: "Rafał Gross",
          phone: "+48 509 770 330",
          email: "rafal.gross@wp.pl",
        },
        {
          id: 19,
          city: "Rybnik/Tychy",
          company: "WALTON CONSULTING SP. Z O.O.",
          name: "Piotr Sulowski",
          phone: "+48 668 104 115",
          email: "piotr@waltonconsulting.pl",
        },
        {
          id: 20,
          city: "Lyski/Tychy",
          company: "Enter-solar",
          name: "Łukasz Pytlik",
          phone: "+48 533 538 532",
          email: "entersolar20@gmail.com",
        },
        {
          id: 21,
          city: "Tychy",
          company: "SDK ENERGY SŁAWOMIR  KOCHANOWSKI",
          name: "Sławomir Kochanowski",
          phone: "+48 505 949 827",
          email: "biuro@sdkenergy.pl",
        },
        {
          id: 21,
          city: "Tychy",
          company: "Diocom",
          name: "Jacek Grzybek",
          phone: "+48 508 045 158",
          email: "serwis@diocom.pl",
        },
        {
          id: 22,
          city: "Bielsko-Biała",
          company: "Zoneko",
          name: "Zbigniew Pajda",
          phone: "+48 606 655 949",
          email: "info@zoneko.pl",
        },
        {
          id: 23,
          city: "Bielsko-Biała",
          company: "Ekosun",
          name: "Patrycjusz Kopacz",
          phone: "33 499 88 66",
          email: "biuro@ekosun.pl",
        },
        {
          id: 24,
          city: "Pszczyna/Czechowice-Dziedzice",
          company: "Go Energy",
          name: "Adam Zalesiński",
          phone: "+48 725 546 060",
          email: "biuro@goenergy.pl",
        },
        {
          id: 25,
          city: "Czechowice-Dziedzice",
          company: "Eurosolar",
          name: "Agnieszka Ślęzak",
          phone: "+48 600 507 084",
          email: "biuro@euro-solar.pl",
        },
        {
          id: 26,
          city: "Skoczów/Czechowice-Dziedzice",
          company: "WIBTECH  Sp. z o.o.",
          name: "Jerzy Wojnar",
          phone: "+48 665 384 572",
          email: "biuro.wibtech@gmail.com",
        },
      ],
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    getInfo(e) {
      this.title = e.target.id;
    },
    setInfo(item) {
      let cityInformation =
        "Prosimy o kontakt w celu uzyskania szczegółowych informacji.";
      this.descriptionOfCity.filter((element) => {
        if (element.id == item) {
          cityInformation = element.description;
          let specialDiv = document.getElementById("actualDescription");
          specialDiv.scrollIntoView({ behavior: "smooth" });
        }
      });
      return (this.actualDescription = cityInformation);
    },
  },
  computed: {
    amountOfElementsInRow: {
      get() {
        if (this.windowWidth <= 576) {
          return 1;
        } else if (this.windowWidth > 576 && this.windowWidth < 768) {
          return 2;
        } else if (this.windowWidth > 768 && this.windowWidth < 992) {
          return 3;
        } else {
          return 4;
        }
      },
    },
  },
  mounted() {
    fetch("images/wojewodztwo-slaskie.svg")
      .then((response) => response.text())
      .then((svg) => {
        this.$refs.svg.insertAdjacentHTML("afterbegin", svg);
        let elementClicked = document.getElementsByClassName("slask_area");
        if (elementClicked) {
          for (let item of elementClicked) {
            item.addEventListener("click", (e) => {
              let dataAreaSvg = e.target.getAttribute("data-area");
              this.setInfo(dataAreaSvg);
            });
          }
        }
      });

    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped lang="scss">
.first-title {
  font-size: 40px;
  line-height: 1.2;
  font-weight: bold;
}

.clickable_city {
  font-weight: bold;
  font-size: 22px;
  margin: 5px 0px;
  cursor: pointer;
}

.clickable_city:hover {
  text-decoration: underline;
}

// .bi-caret-right-fill {
//   color: #63D10C;
// }

.details-info p {
  font-size: 20px;
}

.instalators a {
  // text-decoration: none;
  color: #000;
  word-wrap: break-word;
}

.border-bottom-dashed {
  border-bottom: 2px dashed #62aa29;
}

path {
  fill: #558b2f;
  cursor: pointer;
  -webkit-transition: all 150ms;
  -moz-transition: all 150ms;
  -o-transition: all 150ms;
  transition: all 150ms;
}

path:hover {
  opacity: 0.85;
}

.land {
  fill-opacity: 1;
  stroke: white;
  stroke-opacity: 1;
  stroke-width: 0.5;
}

.max-w-100 {
  max-width: 100%;
}

// .overflow-x-off1{
//   overflow-x: hidden;

// }
.overflow-x-off {
  overflow-x: hidden;
  overflow-y: hidden;

  // height: 500px;
  max-height: 500px;
}

.green-text {
  color: #62aa29;
}

@media only screen and (max-width: 675px) {
  .first-title {
    font-size: 25px;
  }

  .details-info p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .inner-info {
    left: -1px;
  }
}
</style>
