<template>
  <div class="container-special w-100">
    <div class="row">
      <div class="col-12 d-flex align-items-center col-md-7">
            <p class="contact"><strong>Umów spotkanie lub zapytaj o szczegóły:</strong><a class="ml-4 contact" href="mailto:biuro@dofinansowaniaslask.pl">biuro@dofinansowaniaslask.pl</a></p>
      </div>
      <!-- <div class="col-12 d-flex align-items-center col-md-3">
        
        </div> -->
      <div class="col-12 d-flex align-items-center justify-content-start justify-content-md-end col-md-5">
            <p class="contact"><strong>Zapytaj eksperta:</strong><a class="ml-4 contact" href="tel:+48501924953">+48 501 924 953</a></p>
      </div>
      <!-- <div class="col-12 col-md-3">
        
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerComponent',
}
</script>

<style scoped lang="scss">
.h-100{
  height: 100%;
}
.our-site {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  margin-bottom: 2px;
}

.contact {
  text-decoration: none;
  margin-bottom: 2px;
  color: #fff;
}

@media only screen and (min-width: 2000px) {
  .container-special *{
    max-width: 1730px;
    margin: auto;
    width: 100%;
    padding: 5px 25px;

  }
}

@media only screen and (max-width: 980px) {
  .our-site {
    font-size: 18px;
  }

  .contact {
    font-size: 14px;
  }
}

@media only screen and (max-width: 890px) {
  .container-special {
    padding: 10px 30px;
  }
}
</style>
