<template>
    <div class="header">
    <HeaderComponent />
  </div>
  <router-view/>
  <div class="footer">
    <FooterComponentVue />
  </div>
</template>

<script>
import "@/style/bootstrap-grid.min.css";
import "../src/style/bootstrap-icons.css"
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponentVue from '@/components/FooterComponent.vue';

export default {
  name: 'App',
  data() {
    return {

    }
  },
  components: {
    HeaderComponent,
    FooterComponentVue

  },
  mounted() {
    window.onscroll = function () {
      let nav = document.getElementsByClassName('header')[0];
      if (window.scrollY > 300) {
        nav.classList.add("header-border-b");
      } else {
        nav.classList.remove("header-border-b");
      }
    };
    // let mapBytom = document.getElementById('Vector_bytom');

  }

}
</script>

<style lang="scss">
@font-face {
  font-family: Monto;
  src: url('./style/fonts/Montserrat-VariableFont_wght.ttf') format("truetype")
}

* {
  font-family: Monto;
}

.slask_area:hover {
  fill: darkgray;
}

.slask_area:focus {
  outline: none;
}

.outline-none:focus {
  outline: none;
}

.p-static {
  position: static;
}
.contact {
  text-decoration: none;
  margin-bottom: 2px;
  color: #fff;
}

.meet-terms {
  border-top: 2px dashed #62AA29;
  border-bottom: 2px dashed #62AA29;
  border-left: 2px dashed #62AA29;
  padding: 30px;
  position: relative;
  bottom: 0px;
  left: 78px;
}


.container-special {
  padding: 5px 87px;
}

.grant-info {
  padding: 10px 40px;
  background: #62AA29;
  width: 190px;
  color: #ffff;
  font-weight: bold;
  text-align: center;
}

.bi-caret-right-fill {
  color: #63D10C;
}

.grant-info-desc {
  margin-left: 20px;
  font-weight: bold;
  // width: 100%;
}

.header {
  background: #3C3C3C;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 78px;
  color: #fff;

}

.header-border-b {
  border-bottom: 2px solid #f6f9fc;
}

.content {
  margin-top: 78px;
  // max-width: 100%;
  overflow-x: hidden;
}

.footer {
  min-height: 75px;
  background-color: #3C3C3C;
  color: #fff;
  position: relative;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.border-dashed {
  border: 2px dashed #62AA29;
}

.border-top-dashed {
  border-top: 2px dashed #62AA29;

}

@media only screen and (min-width: 2000px) {
  .outer-container {
    max-width: 1730px;
    margin: auto;
  }

  .footer {
    margin: auto;
  }

  .header {
    margin: auto;
  }
}

@media only screen and (max-width: 760px) {
  .container-special {
    padding: 10px 20px;
  }

  .meet-terms {
    // border-top: 2px dashed #62AA29;
    // border-bottom: 2px dashed #62AA29;
    // border-left: 2px dashed #62AA29;
    // padding: 30px;
    // position: relative;
    // bottom: 0px;
    left: 18px;
  }
}

@media only screen and (max-width: 600px) {
  #focus-slaskie {
    width: 100%;
    height: auto;
  }

  .meet-terms {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  .grant-info-desc {
    width: 100%;
    margin: 0 5px 15px 0;
  }

  .meet-terms {
    padding: 10px;
    left: 5px;
  }
}
</style>