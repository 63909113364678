<template>
  <div class="container-special m-t-78">
    <div class="row">
      <div class="col-12 title">
        <h1><strong>gotowe zestawy</strong></h1>
        <p class="description">spełniające wymagania dofinansowań</p>
      </div>
    </div>

    <div id="set-1" class="border-dashed mt-5 d-flex flex-wrap align-items-start justify-content-center">

      <div class="col-12 m-1 m-sm-3">
        <div class="row align-items-center p-2 p-sm-2">

          <p class="set-title mr-3">zestaw 1.</p>
          <p class="to-upper-case"><strong>Podstawowy (na nim opierają się pozostałe zestawy): </strong>
            <br />moduły, falownik, konstrukcja, optymalizatory, rozdzielnice, akcesoria
          </p>
        </div>
      </div>

      <div class="col col-xl-2 d-flex justify-content-center align-items-center py-4">
            <img width="auto" height="auto" src="../assets/set_1/module.jpg" />
      </div>

      <div class="col col-xl-2 d-flex justify-content-center align-items-center py-4">
              <img width="auto" height="auto" src="../assets/set_1/SUN2000-3-10KTL-M1.jpg" />

      </div>
      <div class="col col-xl-2 d-flex justify-content-center align-items-center py-4">
            <img width="auto" height="auto" src="../assets/set_1/k-33.jpg" />
      </div>

      <div class="col col-xl-2 d-flex justify-content-center align-items-start py-4">
            <img width="auto" height="auto" src="../assets/set_1/huawei-optymalizator-mocy-merc-1100w-p.jpg" />
      </div>

      <div class="col col-xl-2 d-flex justify-content-center align-items-start py-4">
            <img width="auto" height="auto" src="../assets/set_1/SH-453N-AC.jpg" />
      </div>

      <div class="col col-xl-2 d-flex justify-content-center align-items-center py-4">
            <img width="auto" height="auto" src="../assets/set_1/accessories.jpg" />
      </div>
    </div>
    <div class="mt-5 d-flex flex-wrap align-items-center justify-content-center">
      <div class="col-12 m-3">
        <div class="row align-items-center">
          <p class="set-title mr-3">zestaw 2.</p>
          <p><strong>Z POMPĄ CIEPŁA </strong>
          </p>
        </div>
      </div>
      <div class="col-auto">
        <p @click="scrollToSet1" class="set-1-outline-base">zestaw 1.</p>
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
        <div class="d-flex">
          <div class="row">
            <div class="col d-flex flex-column justify-content-start align-items-start px-0">
              <img width="auto" height="auto" src="../assets/set_2/pomps-heat.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 d-flex flex-wrap align-items-center justify-content-center border-top-dashed">
      <div class="col-12 m-3">
        <div class="row align-items-center">
          <p class="set-title mr-3">zestaw 3.</p>
          <p class="to-upper-case"><strong>z magazynem energii i licznikiem</strong>
          </p>
        </div>
      </div>
      <div class="col-auto">
        <p @click="scrollToSet1" class="set-1-outline-base">zestaw 1.</p>
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/MThermal_mono.svg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/counter.svg" />
      </div>
    </div>

    <div class="mt-5 d-flex flex-wrap align-items-center justify-content-center border-top-dashed">
      <div class="col-12 m-3">
        <div class="row align-items-center">
          <p class="set-title mr-3">zestaw 4.</p>
          <p class="to-upper-case"><strong>z pompą ciepła, magazynem energii i licznikiem</strong>
          </p>
        </div>
      </div>
      <div class="col-auto">
        <p @click="scrollToSet1" class="set-1-outline-base">zestaw 1.</p>
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_2/pomps-heat.jpg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/MThermal_mono.svg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/counter.svg" />
      </div>
    </div>

    <div class="mt-5 d-flex flex-wrap align-items-center justify-content-center border-top-dashed">
      <div class="col-12 m-3">
        <div class="row align-items-center">
          <p class="set-title mr-3">zestaw 5.</p>
          <p class="to-upper-case"><strong>magazynem energii, backup box'em i licznikiem</strong>
          </p>
        </div>
      </div>
      <div class="col-auto">
        <p @click="scrollToSet1" class="set-1-outline-base">zestaw 1.</p>
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/MThermal_mono.svg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_4/backup_box.jpg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/counter.svg" />
      </div>
    </div>


    <div class="mt-5 d-flex flex-wrap align-items-center justify-content-center border-top-dashed">
      <div class="col-12 m-3">
        <div class="row align-items-center">
          <p class="set-title mr-3">zestaw 6.</p>
          <p class="to-upper-case"><strong>z pompą ciepła, magazynem energii, backup box'em i licznikiem</strong>
          </p>
        </div>
      </div>
      <div class="col-auto">
        <p @click="scrollToSet1" class="set-1-outline-base">zestaw 1.</p>
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_2/pomps-heat.jpg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>

      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_3/MThermal_mono.svg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto">
              <img width="auto" height="auto" src="../assets/set_4/backup_box.jpg" />
      </div>
      <div class="col-12 col-sm-auto">
        <p class="plus">+</p>
      </div>
      <div class="col-auto col-xl-2">
              <img width="auto" height="auto" src="../assets/set_3/counter.svg" />
      </div>
    </div>


  </div>
</template>

<script>

export default {
  name: 'RadySets',
  components: {

  },
  methods:{
    scrollToSet1(){
      let element = document.getElementById('set-1')
      element.scrollIntoView({ behavior: 'smooth'})
    }
  }
}
</script>
<style scoped lang="scss">
.m-t-78 {
  margin-top: 78px;
}

.title p {
  color: #62AA29;
}
.description{
  font-size: 28px;
}

.text-orientation-y {
  writing-mode: vertical-rl;
  transform: scale(-1, -1);
  font-weight: bold;
  color: #62AA29;
  font-size: 24px;
  margin-left: 0px;
}

.set-title {
  padding: 10px 30px;
  background-color: #62AA29;
  color: #ffff;
  font-size: 18px;
}

.set-1-outline-base {
  padding: 10px 30px;
  background-color: #ffff;
  color: #62AA29;
  font-size: 18px;
  font-weight: bold;
  border: 4px solid #62AA29;
  width: fit-content;
  transition: all 0.3s;


}
.set-1-outline-base:hover{
  color: #ffff;
  background-color: #62AA29;
  transition: all 0.3s;
}

.to-upper-case {
  text-transform: uppercase;
}

.plus {
  font-weight: bold;
  font-size: 26px;
}
@media only screen and (min-width: 2000px) {
  .container-special {
    max-width: 1730px;
    margin: auto;
  }
}

@media only screen and (max-width: 578px) {
  .plus {
  margin: 20px 10px;
  text-align: center;
  font-size: 34px;
}
.description{
  font-size: 24px;
}
}
</style>
