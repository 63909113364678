<template>
  <!-- <div class="header">
    <HeaderComponent />
  </div> -->
  <div class="outer-container">
    <div class="content" id="contact">
      <AllContentComponentVue />
    </div>
  </div>
  <!-- <div class="footer">
    <FooterComponentVue />
  </div> -->
</template>

<script>

// import HeaderComponent from '@/components/HeaderComponent.vue'
import AllContentComponentVue from '@/components/AllContentComponent.vue';
export default {
  name: 'HomeView',
  components: {
    // HeaderComponent,
    AllContentComponentVue,
    
  }
}
</script>
